<!--
  https://tailwindui.com/components/application-ui/forms/input-groups
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <div>
    <label for="filter" class="sr-only">Filter</label>
    <div class="mt-1 relative rounded-md shadow-sm">
      <div
        class="
          absolute
          inset-y-0
          left-0
          pl-3
          flex
          items-center
          pointer-events-none
        "
      >
        <FilterIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        name="filter"
        type="text"
        class="
          mb-1
          focus:ring-indigo-500
          focus:border-indigo-500
          block
          w-full
          pl-10
          sm:text-sm
          border-gray-300
          rounded-md
        "
        :placeholder=placeholder
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import { FilterIcon } from '@heroicons/vue/solid';

export default {
  components: {
    FilterIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Filter results',
    },
    modelValue: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue']
};
</script>
